import React, { useState } from 'react';
import './Viewers.css';

const Glow01ProcessorCustomer = ({ data }) => {
  const [expandedGroups, setExpandedGroups] = useState({});

  const toggleGroup = (groupId) => {
    setExpandedGroups(prev => ({
      ...prev,
      [groupId]: !prev[groupId]
    }));
  };

  const renderTargetGroup = (group) => {
    const isExpanded = expandedGroups[group.id];

    return (
      <div key={group.id} className="target-group">
        <h3 onClick={() => toggleGroup(group.id)}>
          <span className={`collapse-indicator ${isExpanded ? 'expanded' : ''}`}>▶</span>
          Group {group.id}: {group.group}
        </h3>
        
        {isExpanded && (
          <div className="group-content">
            <h4>Considered Problems:</h4>
            <ul>
              {group.considered_problems.map((problem, index) => (
                <li key={index}>{problem}</li>
              ))}
            </ul>

            <div className="problem-solution">
              <div className="section">
                <h4>Problem Statement:</h4>
                <p>{group.problem_statement}</p>
              </div>
              
              <div className="section">
                <h4>Solution:</h4>
                <p>{group.solution}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="process-viewer">
      <div className="target-groups-container">
        {data.target_groups?.map(group => renderTargetGroup(group)) || 
          <p>No target groups available</p>}
      </div>
    </div>
  );
};

export default Glow01ProcessorCustomer;
