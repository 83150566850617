import React, { useState } from 'react';
import './Viewers.css';

const Glow03ProcessorScript = ({ data }) => {
  console.log('Received data:', data);

  const [expandedGroups, setExpandedGroups] = useState({});
  const [expandedScenes, setExpandedScenes] = useState({});
  const [expandedFrames, setExpandedFrames] = useState({});

  const toggleGroup = (groupId) => {
    setExpandedGroups(prev => ({
      ...prev,
      [groupId]: !prev[groupId]
    }));
  };

  const toggleScene = (sceneId) => {
    setExpandedScenes(prev => ({
      ...prev,
      [sceneId]: !prev[sceneId]
    }));
  };

  const toggleFrame = (frameId) => {
    setExpandedFrames(prev => ({
      ...prev,
      [frameId]: !prev[frameId]
    }));
  };

  const renderFrame = (frame, groupId, sceneId) => {
    const frameKey = `${groupId}_${sceneId}_${frame.frame_number}`;
    const isExpanded = expandedFrames[frameKey];
    
    return (
      <div className="frame-card" key={frame.frame_number}>
        <div 
          className="frame-header"
          onClick={() => toggleFrame(frameKey)}
        >
          <h4>
            <span className={`collapse-indicator ${isExpanded ? 'expanded' : ''}`}>▶</span>
            Frame {frame.frame_number} ({frame.frame_type})
          </h4>
        </div>
        
        {isExpanded && (
          <div className="frame-content">
            {frame.primary_person && (
              <div className="frame-item">
                <strong>Primary Person:</strong>
                <p>Description: {frame.primary_person.description}</p>
                <p>Action: {frame.primary_person.action}</p>
                <p>Expression: {frame.primary_person.expression}</p>
              </div>
            )}
            
            {frame.environment && (
              <div className="frame-item">
                <strong>Environment:</strong>
                <p>Setting: {frame.environment.setting}</p>
                <p>Background: {frame.environment.background}</p>
                <p>Props: {frame.environment.props.join(', ')}</p>
              </div>
            )}
            
            {frame.technical && (
              <div className="frame-item">
                <strong>Technical:</strong>
                <p>Camera: {frame.technical.camera}</p>
                <p>Lighting: {frame.technical.lighting}</p>
                {frame.technical.color_grade && (
                  <p>Color Grade: {frame.technical.color_grade}</p>
                )}
              </div>
            )}
            
            {frame.audio && (
              <div className="frame-item">
                <strong>Audio:</strong>
                <p>Narration: {frame.audio.narration || 'None'}</p>
                <p>Music: {frame.audio.music}</p>
              </div>
            )}
            
            <div className="frame-details">
              <div className="frame-item">
                <strong>Visual Prompt:</strong>
                <p>{frame.full_visual_prompt}</p>
              </div>
              <p><strong>Duration:</strong> {frame.duration}</p>
              <p><strong>Transition:</strong> {frame.transition}</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderScene = (sceneData, groupId) => {
    const isExpanded = expandedScenes[`${groupId}_${sceneData.scene_id}`];
    
    return (
      <div key={`${groupId}_${sceneData.scene_id}`} className="scene-card">
        <div 
          className="scene-header"
          onClick={() => toggleScene(`${groupId}_${sceneData.scene_id}`)}
        >
          <h4>
            <span className={`collapse-indicator ${isExpanded ? 'expanded' : ''}`}>▶</span>
            {sceneData.scene_type} (Scene {sceneData.scene_id})
          </h4>
        </div>
        
        {isExpanded && (
          <div className="scene-content">
            <div className="scene-details">
              <p><strong>Scene Length:</strong> {sceneData.scene_length}</p>
              <div className="scene-item">
                <strong>Negative Visual Prompt:</strong>
                <p>{sceneData.visu_neg_prompt}</p>
              </div>
            </div>
            
            <div className="frames-container">
              <h5>Frames:</h5>
              {sceneData.frames?.map(frame => 
                renderFrame(frame, groupId, sceneData.scene_id)
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderTargetGroup = (groupData, groupId) => {
    const isExpanded = expandedGroups[groupId];
    
    return (
      <div key={groupId} className="target-group">
        <h3 
          onClick={() => toggleGroup(groupId)}
        >
          <span className={`collapse-indicator ${isExpanded ? 'expanded' : ''}`}>▶</span>
          {groupData.target_group || `Target Group ${groupId}`}
        </h3>
        
        {isExpanded && (
          <div className="group-content">
            {renderScene(groupData, groupId)}
          </div>
        )}
      </div>
    );
  };

  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <div className="process-viewer">
      {Object.entries(data).map(([groupId, group]) => 
        renderTargetGroup(group, groupId)
      )}
    </div>
  );
};

export default Glow03ProcessorScript;

