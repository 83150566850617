import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './Icicle.css';
import { FaEye, FaPlay, FaCheck, FaSpinner, FaClock } from 'react-icons/fa';
import { motion } from 'framer-motion';

// Define icon types and their properties
const ICON_TYPES = {
  VIEW: {
    icon: FaEye,
    position: { x: 10, y: 10 }, // Top-left corner of each node
    size: 16,
    activeOnly: true
  },
  PLAY: {
    icon: FaPlay,
    position: { x: 10, y: 35 }, // Below the view icon
    size: 16,
    activeOnly: true
  },
  STATUS: {
    icon: FaClock, // Default icon, will change based on state
    position: { x: 35, y: 10 }, // Top-right corner
    size: 16,
    activeOnly: false
  }
};

const getBaseFontSize = (level) => {
  switch(level) {
    case -1: // root node (Startup Journey)
      return 38;  // Increased from 24 to 32
    case 0:  // main categories (Idea Validation, Video Production, Market Entry)
      return 30;
    case 1:  // subcategories
      return 26;
    case 2:  // leaf nodes
      return 24;
    default:
      return 19;
  }
};

const renderNodeIcons = (node, d, onViewClick, onPlayClick) => {
  const isActive = d.data.state === 'active';
  const nodeWidth = d.x1 - d.x0;
  const nodeHeight = d.y1 - d.y0;

  // Only add icons if node is big enough
  if (nodeWidth < 50 || nodeHeight < 50) return;

  // Create a container for icons
  const iconContainer = node.append('g')
    .attr('class', 'icon-container')
    .style('pointer-events', 'all')
    .style('z-index', '1000');

  const iconSpacing = 30;
  let currentY = nodeHeight - 20;

  // Add info icon for all nodes
  const infoIcon = iconContainer.append('g')
    .attr('class', 'icon-info')
    .attr('transform', `translate(${nodeWidth - 30}, ${currentY})`)
    .style('cursor', 'pointer')
    .on('mouseover', (event) => {
      // Show tooltip with info
      const tooltip = d3.select('body').append('div')
        .attr('class', 'icicle-tooltip')
        .style('position', 'absolute')
        .style('left', `${event.pageX + 10}px`)
        .style('top', `${event.pageY - 10}px`);

      tooltip.html(`
        <h3>${d.data.info?.title || d.data.id}</h3>
        <p>${d.data.info?.description || 'No description available.'}</p>
      `);
    })
    .on('mouseout', () => {
      // Remove tooltip
      d3.selectAll('.icicle-tooltip').remove();
    });

  infoIcon.append('circle')
    .attr('r', 12)
    .attr('fill', 'white')
    .attr('opacity', 0.3);

  infoIcon.append('text')
    .attr('font-family', 'Font Awesome 5 Free')
    .attr('font-size', '14px')
    .attr('fill', 'black')
    .attr('text-anchor', 'middle')
    .attr('dy', '0.35em')
    .text('ℹ');

  currentY -= iconSpacing;

  // Only add view icon for active nodes
  if (isActive) {
    const viewIcon = iconContainer.append('g')
      .attr('class', 'icon-view')
      .attr('transform', `translate(${nodeWidth - 30}, ${currentY})`)
      .style('cursor', 'pointer')
      .on('click', (event) => {
        event.stopPropagation();
        // Find the corresponding process ID and scroll to it
        const processId = d.data.processId; // This should be added to your icicle data structure
        if (processId) {
          const element = document.getElementById(processId);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            element.classList.add('highlight');
            setTimeout(() => element.classList.remove('highlight'), 2000);
          }
        }
        onViewClick?.(d.data);
      });

    viewIcon.append('circle')
      .attr('r', 12)
      .attr('fill', 'white')
      .attr('opacity', 0.5);

    viewIcon.append('text')
      .attr('font-family', 'Font Awesome 5 Free')
      .attr('font-size', '14px')
      .attr('fill', 'black')
      .attr('text-anchor', 'middle')
      .attr('dy', '0.35em')
      .text('👁');

    currentY -= iconSpacing;

    // Add play icon for active leaf nodes
    if (d.data.level === 2) {
      const playIcon = iconContainer.append('g')
        .attr('class', 'icon-play')
        .attr('transform', `translate(${nodeWidth - 30}, ${currentY})`)
        .style('cursor', 'pointer')
        .on('click', (event) => {
          event.stopPropagation();
          onPlayClick?.(d.data);
        });

      playIcon.append('circle')
        .attr('r', 12)
        .attr('fill', 'white')
        .attr('opacity', 0.5);

      playIcon.append('text')
        .attr('font-family', 'Font Awesome 5 Free')
        .attr('font-size', '14px')
        .attr('fill', d.data.status === 'running' ? 'var(--color-primary)' : 'black')
        .attr('text-anchor', 'middle')
        .attr('dy', '0.35em')
        .text(d.data.status === 'running' ? '⏳' : '▶')
        .attr('class', d.data.status === 'running' ? 'running' : '');
    }
  }
};

const Icicle = ({ data, onNodeClick, onViewClick, onPlayClick }) => {
  const svgRef = useRef(null);
  const { colors = {} } = data.config?.appearance || {};

  // Add state for dimensions
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0
  });

  // Add resize listener
  useEffect(() => {
    const updateDimensions = () => {
      if (svgRef.current) {
        setDimensions({
          width: svgRef.current.clientWidth,
          height: svgRef.current.clientHeight
        });
      }
    };

    // Initial dimensions
    updateDimensions();

    // Add event listener
    window.addEventListener('resize', updateDimensions);

    // Cleanup
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  // Add data verification
  useEffect(() => {
    if (!data) {
      console.error('No data provided to Icicle component');
      return;
    }
    
    if (!data.config || !data.children) {
      console.error('Invalid data structure:', data);
      return;
    }

    console.log('Icicle received data:', data);

    if (!data || !svgRef.current || !dimensions.width || !dimensions.height) return;

    // Clear previous chart
    d3.select(svgRef.current).selectAll("*").remove();

    // Use dimensions from state instead of clientWidth/Height
    const width = dimensions.width;
    const height = dimensions.height;

    // Create the SVG container
    const svg = d3.select(svgRef.current)
      .attr("viewBox", [0, 0, width, height])
      .style("font", "12px sans-serif");

    // Create a new root object with an id
    const rootData = {
      id: "Product Success",
      children: data.children,
      state: "active",
      level: -1  // Add this to explicitly set root level
    };

    // Create hierarchy with the new root object
    const root = d3.hierarchy(rootData)
      .sum(d => d.children ? 0 : 1)
      ;

    // Create partition layout
    const partition = d3.partition()
      .size([width, height])
      .padding(1);

    partition(root);

    // Define colors for states
    const stateColors = {
      active: '#FD9800',      // Orange for active state
      coming_soon: '#8B8C91', // Grey for coming_soon state
      working: '#C4914B'      
    };

    // Create color scale based on state
    const colorScale = d => {
      return d.data.state === 'active' ? stateColors.active : d.data.state === 'working' ? stateColors.working : stateColors.coming_soon;
    };

    // Add cells
    const cell = svg
      .selectAll("g")
      .data(root.descendants())
      .join("g")
      .attr("transform", d => `translate(${d.x0},${d.y0})`);

    // Add rectangles with motion
    cell.append("rect")
      .attr("width", d => d.x1 - d.x0)
      .attr("height", d => d.y1 - d.y0)
      .attr("fill", d => stateColors[d.data.state])
      .attr("stroke", colors.border)
      .style("cursor", "pointer")
      .attr("class", "icicle-rect")
      .on("mouseenter", function(event, d) {
        const color = stateColors[d.data.state];
        d3.select(this)
          .style("filter", `
            brightness(1.2)
            contrast(1.1)
            saturate(1.2)
            drop-shadow(0 0 4px ${color})
            drop-shadow(0 0 8px ${color})
          `);
      })
      .on("mouseleave", function() {
        d3.select(this)
          .style("filter", "none");
      });

    // Add text next (middle layer)
    cell.append("text")
      .attr("pointer-events", "none")
      .each(function(d) {
        const node = d3.select(this);
        const width = d.x1 - d.x0;
        const height = d.y1 - d.y0;
        
        // Get base font size based on level
        const baseFontSize = getBaseFontSize(d.data.level);
        
        // Calculate actual font size
        const fontSize = Math.min(
          baseFontSize,
          width / 5,  // Reduced from 4 to 6 to allow more text
          height / 4
        );
        
        // Skip if node is too small
        if (width < 15 || height < 12) {
          node.remove();
          return;
        }

        const text = d.data.id;
        // Split on spaces and parentheses
        const words = text.split(/[\s()]+/).filter(word => word.length > 0);
        
        // Setup text attributes
        node
          .style("font-size", `${fontSize}px`)
          .style("font-weight", d => d.data.level === -1 ? "700" : d.data.level <= 0 ? "600" : "500")
          .style("font-family", "Arial, sans-serif")
          .attr("fill", d => d.data.state === 'coming_soon' ? '#ffffff' : '#000000')
          .attr("text-anchor", "middle")
          .attr("transform", `translate(${width/2}, ${fontSize})`);

        // Improved line breaking logic
        let lines = [];
        let currentLine = [];
        let currentWidth = 0;
        const padding = 10;
        const maxWidth = width - padding * 2;

        words.forEach((word, i) => {
          // Add special handling for parentheses
          const testText = currentLine.length === 0 ? word : currentLine.join(' ') + ' ' + word;
          node.append("tspan")
            .text(testText)
            .attr("visibility", "hidden");
          
          const testWidth = node.select("tspan").node().getComputedTextLength();
          node.selectAll("tspan").remove();

          if (testWidth > maxWidth && currentLine.length > 0) {
            lines.push(currentLine.join(' '));
            currentLine = [word];
          } else {
            currentLine.push(word);
          }

          // Handle last word
          if (i === words.length - 1 && currentLine.length > 0) {
            lines.push(currentLine.join(' '));
          }
        });

        // Render lines
        const lineHeight = fontSize * 1.2;
        const maxLines = Math.floor((height - fontSize) / lineHeight);
        lines.slice(0, maxLines).forEach((line, i) => {
          node.append("tspan")
            .text(line)
            .attr("x", 0)
            .attr("dy", i === 0 ? 0 : lineHeight);
        });

        // Remove text if no lines were added
        if (!node.selectAll("tspan").size()) {
          node.remove();
        }
      });

    // Add icons last (top layer)
    cell.each(function(d) {
      renderNodeIcons(d3.select(this), d, onViewClick, onPlayClick);
    });

    // Add click handler
    cell.on("click", (event, d) => {
      event.stopPropagation();
      onNodeClick?.(d.data);
    });

  }, [data, colors, dimensions, onNodeClick, onViewClick, onPlayClick]);

  return (
    <div className="icicle-container">
      <svg ref={svgRef} width="100%" height="100%" />
    </div>
  );
};

export default Icicle;
