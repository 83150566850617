import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './App.css';
import { FaCheckCircle, FaList, FaPlusSquare, FaCircle, FaTools, FaCopy, FaDownload, FaMagic, FaSave, FaRedo, FaCheck } from 'react-icons/fa'; // Make sure to install react-icons package
import Lottie from 'lottie-react';
import loadingAnimation from './animations/loading.json'; // You'll need to add this file
import Glow01ProcessorCustomer from './components/viewers/glow_01_processor_customer';
import Glow02ProcessorScene from './components/viewers/glow_02_processor_scene';
import Glow03ProcessorScript from './components/viewers/glow_03_processor_script';
import { 
  getProcessById, 
  getDisplayName, 
  canRunProcess, 
  getProcessResponse,
  getProcessStatus,
  getEndpoint,
  getAllProcesses,
  updateSessionDetails 
} from './utils/processUtils';
import ThemeToggle from './components/ThemeToggle';
import processConfig from './config/processIndex.json';
import Icicle from './components/Icicle';
import icicle_structure from './config/icicle_structure.json';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ICICLE_TO_PROCESS_MAP = {
  "1) Opportunity Size Analysis": "glow_01",
  "2) Build Scenes": "glow_02",
  "3) Build Script": "glow_03",
  "4) Customer Segmentation": "glow_04",
  // Add all your process mappings here
};

function App() {
  const [sessionId, setSessionId] = useState('');
  const [inputText, setInputText] = useState('');
  const [sessionDetails, setSessionDetails] = useState(null);
  const [devToolOpen, setDevToolOpen] = useState(false);
  const [apiResponses, setApiResponses] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [runningProcesses, setRunningProcesses] = useState({});  // Changed from object to map of session IDs
  const [isAnyProcessRunning, setIsAnyProcessRunning] = useState(false);
  const [toast, setToast] = useState({ show: false, message: '', type: '' });
  const [currentProcess, setCurrentProcess] = useState('');
  const modalRef = useRef(null);
  const [showSessionOptions, setShowSessionOptions] = useState(false);
  const [newSessionName, setNewSessionName] = useState('');
  const [sessionList, setSessionList] = useState([]);
  const [showSessionList, setShowSessionList] = useState(false);
  const [editingSessionId, setEditingSessionId] = useState(null);
  const dropdownRef = useRef(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);
  const [isJsonView, setIsJsonView] = useState(true);
  const [isProcessViewCollapsed, setIsProcessViewCollapsed] = useState({});
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [loadingButtons, setLoadingButtons] = useState({});
  const [finishedProcesses, setFinishedProcesses] = useState({});
  const [icicleData, setIcicleData] = useState(null);
  const [width, setWidth] = useState(window.innerWidth * 0.8);
  const [height, setHeight] = useState(window.innerHeight * 0.6);

  // Add this useEffect to handle theme changes
  useEffect(() => {
    document.body.className = isDarkTheme ? 'theme-dark' : 'theme-light';
  }, [isDarkTheme]);

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  const fetchSessionList = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/session/get_session_list`,
        {
          headers: { Authorization: `Bearer ${Cookies.get('token')}` },
        }
      );
      setSessionList(response.data.data.sessions || []);
      return response.data;
    } catch (error) {
      console.error('Error fetching session list:', error);
      showToast('Failed to fetch session list');
      return { status_code: 400, data: [] };
    }
  };

  const createSession = async () => {
    try {
      setIsCreating(true);
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/session/create_session`,
        { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
      );
      const newSessionId = response.data.data.session_id;
      setSessionId(newSessionId);
      Cookies.set('session_id', newSessionId);
      addApiResponse(response.data);
      await getSessionDetails(newSessionId);
      await fetchSessionList();
      showToast('New session created successfully', 'success');
    } catch (error) {
      console.error('Error creating session:', error);
      addApiResponse({ status: false, message: 'Error creating session' });
      showToast('Failed to create new session', 'error');
    } finally {
      setIsCreating(false);
    }
  };

  useEffect(() => {
    const initializeSessionSelection = async () => {
      const listResponse = await fetchSessionList();
      
      // Check if response is valid and has sessions
      const hasValidSessions = listResponse.data?.sessions && 
                              Array.isArray(listResponse.data.sessions) && 
                              listResponse.data.sessions.length > 0;

      // Create new session if:
      // 1. Response has error (status_code 400)
      // 2. Response data is empty or invalid
      // 3. No sessions in the list
      if (listResponse.status_code === 400 || !hasValidSessions) {
        await createSession();
        return;
      }

      // If we have valid sessions, proceed with selection logic
      const savedSessionId = Cookies.get('session_id');
      const sessionExists = listResponse.data.sessions.some(session => session.session_id === savedSessionId);
      
      if (savedSessionId && sessionExists) {
        setSessionId(savedSessionId);
        await getSessionDetails(savedSessionId);
      } else {
        const firstSessionId = listResponse.data.sessions[0].session_id;
        setSessionId(firstSessionId);
        Cookies.set('session_id', firstSessionId);
        await getSessionDetails(firstSessionId);
      }
    };

    initializeSessionSelection();
  }, []);

  const addApiResponse = (response) => {
    const timestamp = new Date().toLocaleString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    setApiResponses((prev) => [...prev, { ...response, showDetails: false, timestamp }]);
  };

  const toggleResponseDetails = (index) => {
    setApiResponses((prev) =>
      prev.map((resp, i) =>
        i === index ? { ...resp, showDetails: !resp.showDetails } : resp
      )
    );
  };

  const getSessionDetails = async (id) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/session/get_session_details`,
        { session_id: id },
        { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
      );

      console.log('Full Response:', response.data);
      console.log('Session Details:', response.data.data.session_details);
      
      setSessionDetails(response.data.data.session_details);
      addApiResponse(response.data);

      // Try both possible locations for input text
      const inputPrompt = response.data.data.session_details.input_text || 
                         response.data.data.session_details.glow_01_data?.input_text ||
                         response.data.data.session_details.input_prompt;
                       
      console.log('Found Input Prompt:', inputPrompt);

      if (inputPrompt && inputPrompt.trim() !== '') {
        setInputText(inputPrompt);
        console.log('Setting Input Text to:', inputPrompt);
      } else {
        setInputText('');
        console.log('No input prompt found, setting empty string');
      }
    } catch (error) {
      console.error('Error getting session details:', error);
      addApiResponse({ status: false, message: 'Error getting session details' });
    }
  };

  const formatJSON = (json) => {
    try {
      return JSON.stringify(JSON.parse(json), null, 2);
    } catch {
      return json;
    }
  };

  const openModal = (content) => {
    setModalContent(formatJSON(content));
    setModalOpen(true);
    setTimeout(() => {
      if (modalRef.current) {
        modalRef.current.classList.add('open');
      }
    }, 10);
  };

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.classList.remove('open');
    }
    setTimeout(() => {
      setModalOpen(false);
    }, 300); // Match this with the transition duration
  };

  const showToast = (message, type = 'info') => {
    setToast({ show: true, message, type });
    setTimeout(() => {
      setToast({ show: false, message: '', type: '' });
    }, 3000);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      showToast('Copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
      showToast('Failed to copy to clipboard');
    });
  };

  const downloadContent = (content, process) => {
    const element = document.createElement('a');
    const file = new Blob([JSON.stringify(JSON.parse(content), null, 2)], {type: 'application/json'});
    element.href = URL.createObjectURL(file);
    const sessionName = sessionDetails?.session_name || 'session';
    element.download = `${sessionName}_${process}_response.json`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const viewResponse = async (process) => {
    if (!sessionId) {
      console.error('No session ID available');
      addApiResponse({ status: false, message: 'No session ID available' });
      return;
    }

    const timeout = (ms) => new Promise((_, reject) => setTimeout(() => reject(new Error('Request timed out')), ms));

    try {
      const response = await Promise.race([
        axios.post(
          `${API_BASE_URL}/api/v1/session/get_session_details`,
          { session_id: sessionId },
          { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
        ),
        timeout(5000) // 5 second timeout
      ]);
      
      const sessionDetails = response.data.data.session_details;
      let content = '';
      
      switch(process) {
        case 'glow_01':
          content = sessionDetails.glow_01_data ? JSON.stringify(sessionDetails.glow_01_data.glow_01_response) : 'No data available';
          break;
        case 'glow_02':
          content = sessionDetails.glow_02_data ? JSON.stringify(sessionDetails.glow_02_data.glow_02_response) : 'No data available';
          break;
        case 'glow_03':
          content = sessionDetails.glow_03_data ? JSON.stringify(sessionDetails.glow_03_data.glow_03_response) : 'No data available';
          break;
        default:
          content = 'Invalid process';
      }
      
      setCurrentProcess(process);
      openModal(content);
    } catch (error) {
      if (error.message === 'Request timed out') {
        console.error('Request timed out. The server might be busy.');
        addApiResponse({ status: false, message: 'Request timed out. Please try again later.' });
      } else {
        console.error('Error getting session details:', error);
        addApiResponse({ status: false, message: 'Error getting session details' });
      }
    }
  };

  const runProcess = async (processId) => {  // Remove targetsessionId parameter
    if (!sessionId) {  // Use the component's sessionId state
      showToast('No session selected', 'error');
      return;
    }

    // Update running state for specific session and process
    setRunningProcesses(prev => ({
      ...prev,
      [sessionId]: {  // Use the component's sessionId
        ...(prev[sessionId] || {}),
        [processId]: true
      }
    }));

    try {
      const payload = { 
        session_id: sessionId,  // Use the component's sessionId
      };

      if (processId === 'glow_01') {
        payload.input_prompt = inputText;
        const targetGroupCount = document.querySelector('.target-group-count-input')?.value || '3';
        payload.number_of_target_group = parseInt(targetGroupCount, 10);
      }

      const response = await axios.post(
        `${API_BASE_URL}${getEndpoint(processId, 'run')}`,
        payload,
        { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
      );
      
      addApiResponse(response.data);
      await getSessionDetails(sessionId);  // Use the component's sessionId
      showToast(`${getDisplayName(processId)} completed successfully`, 'success');
      setFinishedProcesses(prev => ({
        ...prev,
        [processId]: true
      }));
    } catch (error) {
      console.error(`Error running ${processId}:`, error);
      addApiResponse({ status: false, message: `Error running ${processId}` });
      showToast(`Failed to run ${getDisplayName(processId)}`, 'error');
      setFinishedProcesses(prev => ({
        ...prev,
        [processId]: false
      }));
    } finally {
      setRunningProcesses(prev => ({
        ...prev,
        [sessionId]: {  // Use the component's sessionId
          ...(prev[sessionId] || {}),
          [processId]: false
        }
      }));
    }
  };

  // Process function that checks if process can be run
  const processFunction = async (processId) => {
    if (isProcessRunning(sessionId, processId)) {
      showToast('Process already running', 'warning');
      return;
    }

    // Set loading state for this specific button
    setLoadingButtons(prev => ({ ...prev, [processId]: true }));
    
    // Disable other main buttons while one is running
    setIsAnyProcessRunning(true);

    try {
      await runProcess(processId);
    } finally {
      setLoadingButtons(prev => ({ ...prev, [processId]: false }));
      setIsAnyProcessRunning(false);
    }
  };

  const createNewSession = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/session/create_session`, {
        headers: { Authorization: `Bearer ${Cookies.get('token')}` },
      });
      const newSessionId = response.data.data.session_id;
      setSessionId(newSessionId);
      Cookies.set('session_id', newSessionId);
      addApiResponse(response.data);
      getSessionDetails(newSessionId);
      fetchSessionList();
      showToast('New session created successfully');
    } catch (error) {
      console.error('Error creating new session:', error);
      addApiResponse({ status: false, message: 'Error creating new session' });
      showToast('Failed to create new session');
    }
  };

  const startRenameSession = (id, currentName) => {
    setEditingSessionId(id);
    setNewSessionName(currentName);
  };

  const cancelRenameSession = () => {
    setEditingSessionId(null);
    setNewSessionName('');
  };

  const confirmRenameSession = async (id) => {
    if (!newSessionName || !newSessionName.trim()) {
      showToast('Please enter a new name for the session');
      return;
    }
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/session/rename_session`,
        { session_id: id, new_name: newSessionName },
        { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
      );
      addApiResponse(response.data);
      fetchSessionList();
      setEditingSessionId(null);
      setNewSessionName('');
      showToast('Session renamed successfully');
    } catch (error) {
      console.error('Error renaming session:', error);
      addApiResponse({ status: false, message: 'Error renaming session' });
      showToast('Failed to rename session');
    }
  };

  const initiateDeleteSession = (sessionId, sessionName) => {
    setDeleteConfirmation({ id: sessionId, name: sessionName });
  };

  const cancelDeleteSession = () => {
    setDeleteConfirmation(null);
  };

  const deleteSession = async (sessionId) => {
    try {
      setIsDeleting(true);
      setDeleteConfirmation(null); // Close the confirmation modal

      const response = await axios.post(
        `${API_BASE_URL}/api/v1/session/delete_session`,
        { session_id: sessionId },
        { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
      );

      // Fetch updated session list after deletion
      const updatedListResponse = await fetchSessionList();
      
      const hasRemainingSessions = updatedListResponse.data?.sessions && 
                                  Array.isArray(updatedListResponse.data.sessions) && 
                                  updatedListResponse.data.sessions.length > 0;

      if (!hasRemainingSessions) {
        await createSession();
      } else {
        const firstSessionId = updatedListResponse.data.sessions[0].session_id;
        setSessionId(firstSessionId);
        Cookies.set('session_id', firstSessionId);
        await getSessionDetails(firstSessionId);
      }

      showToast('Session deleted successfully');
    } catch (error) {
      console.error('Error deleting session:', error);
      showToast('Failed to delete session');
    } finally {
      setIsDeleting(false);
    }
  };

  const confirmDeleteSession = async () => {
    if (deleteConfirmation) {
      await deleteSession(deleteConfirmation.id);
    }
  };

  const changeCurrentSession = (id) => {
    setSessionId(id);
    Cookies.set('session_id', id);
    getSessionDetails(id);
    setShowSessionList(false);
  };

  const openSessionList = async () => {
    fetchSessionList();
    setShowSessionList(true);
  };

  const selectSession = async (id) => {
    setSessionId(id);
    Cookies.set('session_id', id);
    setShowSessionList(false);
    await getSessionDetails(id);
    showToast('Session selected successfully');
  };

  const renameSession = async (sessionId, newName) => {
    try {
      setIsRenaming(true);
      const response = await axios.post(`${API_BASE_URL}/api/v1/session/rename_session`, {
        session_id: sessionId,
        new_name: newName
      }, {
        headers: { 
          Authorization: `Bearer ${Cookies.get('token')}` 
        }
      });
      
      await fetchSessionList();
      setEditingSessionId(null);
      setNewSessionName('');
      showToast('Session renamed successfully', 'success');
    } catch (error) {
      console.error('Error renaming session:', error);
      showToast('Failed to rename session', 'error');
    } finally {
      setIsRenaming(false);
    }
  };

  // Helper function to check if a specific session has any running process
  const isSessionProcessRunning = (checkSessionId) => {
    return runningProcesses[checkSessionId] && 
           Object.values(runningProcesses[checkSessionId]).some(isRunning => isRunning);
  };

  // Helper function to check if a specific process is running for a session
  const isProcessRunning = (checkSessionId, processId) => {
    return Boolean(runningProcesses[checkSessionId]?.[processId]);
  };

  const formatPrettyJson = () => {
    try {
      const parsed = JSON.parse(modalContent);
      setModalContent(JSON.stringify(parsed, null, 2));
    } catch (error) {
      showToast('Invalid JSON format', 'error');
    }
  };

  const saveContent = async () => {
    try {
      // Validate JSON
      const parsedContent = JSON.parse(modalContent);
      if (Object.keys(parsedContent).length === 0) {
        showToast('Cannot save empty JSON object', 'error');
        return;
      }

      setIsSaving(true);
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/session/update_session_response`,
        {
          process: currentProcess,
          session_id: sessionId,
          updated_response: parsedContent
        },
        { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
      );

      if (response.data.status) {
        // After successful save, fetch updated session details
        const sessionResponse = await axios.post(
          `${API_BASE_URL}/api/v1/session/get_session_details`,
          { session_id: sessionId },
          { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
        );

        if (sessionResponse.data.status) {
          setSessionDetails(sessionResponse.data.data);
          showToast('Changes saved successfully', 'success');
          setHasUnsavedChanges(false);
          setShowSaveConfirmation(false);
        } else {
          showToast('Changes saved but failed to refresh data', 'warning');
        }
      } else {
        showToast(response.data.message || 'Failed to save changes', 'error');
      }
    } catch (error) {
      if (error.name === 'SyntaxError') {
        showToast('Invalid JSON format', 'error');
      } else {
        console.error('Error saving content:', error);
        showToast(error.response?.data?.message || 'Failed to save changes', 'error');
      }
    } finally {
      setIsSaving(false);
    }
  };

  // Update modal content handler
  const handleModalContentChange = (e) => {
    setModalContent(e.target.value);
    setHasUnsavedChanges(true);
  };

  // Handle modal close
  const handleModalClose = () => {
    if (hasUnsavedChanges) {
      setShowDiscardConfirmation(true);
    } else {
      closeModal();
    }
  };

  // Add confirmation handlers
  const handleSaveConfirm = () => {
    saveContent();
  };

  const handleDiscardConfirm = () => {
    setShowDiscardConfirmation(false);
    setHasUnsavedChanges(false);
    closeModal();
  };

  // Add this function to get the process display name
  const getProcessDisplayName = (processName) => {
    return getDisplayName(processName);
  };

  // Add this function to check if the content is valid JSON
  const isValidJson = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  // First, update the process columns rendering
  const renderProcessColumns = () => {
    const processes = getAllProcesses();
    const categories = {
      text: processes.filter(p => p.category === 'text'),
      pictures: processes.filter(p => p.category === 'pictures')
    };

    return (
      <div className="dashboard-content">
        <div className="process-controls">
          {/* Text Category */}
          <div className="process-category">
            <h2 className="category-title">{processConfig.uiStrings.categoryTitles.text}</h2>
            <div className="category-content">
              {categories.text.map(process => (
                <div key={process.id} className="process-column">
                  <div className="process-header">
                    <button 
                      className={`collapse-button ${isProcessViewCollapsed[process.id] ? 'collapsed' : ''}`}
                      onClick={() => setIsProcessViewCollapsed(prev => ({
                        ...prev,
                        [process.id]: !prev[process.id]
                      }))}
                    >
                      <span>{isProcessViewCollapsed[process.id] ? '►' : '▼'}</span>
                    </button>
                    <h2>{process.displayName}</h2>
                  </div>
                  
                  <div className="process-actions">
                    {process.id === 'glow_01' && (
                      <div className="target-group-input">
                        <label htmlFor="targetGroupCount">{processConfig.uiStrings.targetGroupsLabel}</label>
                        <input
                          id="targetGroupCount"
                          type="number"
                          min="2"
                          max="20"
                          defaultValue="2"
                          className="target-group-count-input"
                        />
                      </div>
                    )}
                    <button
                      className={`process-button 
                        ${loadingButtons[process.id] ? 'loading' : ''} 
                        ${finishedProcesses[process.id] ? 'finished' : ''}
                        ${isAnyProcessRunning && !loadingButtons[process.id] && !finishedProcesses[process.id] ? 'waiting' : ''}
                      `}
                      onClick={() => processFunction(process.id)}
                      disabled={!canRunProcess(process.id, sessionDetails) || isSessionProcessRunning(sessionId) || loadingButtons[process.id]}
                    >
                      {loadingButtons[process.id] ? (
                        <>
                          <span className="loading-spinner" />
                          Running {process.displayName}...
                        </>
                      ) : finishedProcesses[process.id] ? (
                        <>
                          <FaCheck />
                          <span>{process.completedText}</span>
                          <span 
                            className="rerun-icon"
                            onClick={(e) => handleRerun(process.id, e)}
                            title="Run Again"
                          >
                            <FaRedo />
                          </span>
                        </>
                      ) : (
                        <>Run {process.displayName}</>
                      )}
                    </button>
                    <button
                      className="view-button"
                      onClick={() => viewResponse(process.id)}
                      disabled={!getProcessStatus(process.id, sessionDetails)}
                    >
                      {processConfig.uiStrings.viewJsonButton}
                    </button>
                  </div>

                  {!isProcessViewCollapsed[process.id] && 
                   getProcessStatus(process.id, sessionDetails) && (
                    <div className="process-view-container">
                      {process.id === 'glow_02' ? (
                        <Glow02ProcessorScene 
                          data={getProcessResponse(process.id, sessionDetails) || {}}
                          onUpdate={async (updatedData) => {
                            await updateSessionDetails(sessionId, updatedData);
                            await getSessionDetails(sessionId);
                          }}
                        />
                      ) : process.id === 'glow_03' ? (
                        <Glow03ProcessorScript 
                          data={getProcessResponse(process.id, sessionDetails) || {}} 
                        />
                      ) : (
                        <Glow01ProcessorCustomer 
                          data={getProcessResponse(process.id, sessionDetails) || { target_groups: [] }}
                          />
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Pictures Category */}
          <div className="process-category">
            <h2 className="category-title">{processConfig.uiStrings.categoryTitles.pictures}</h2>
            <div className="category-content">
              {categories.pictures.map(process => (
                // Same process panel structure as above
                <div key={process.id} className="process-column">
                  <div className="process-header">
                    <button 
                      className={`collapse-button ${isProcessViewCollapsed[process.id] ? 'collapsed' : ''}`}
                      onClick={() => setIsProcessViewCollapsed(prev => ({
                        ...prev,
                        [process.id]: !prev[process.id]
                      }))}
                    >
                      <span>{isProcessViewCollapsed[process.id] ? '►' : '▼'}</span>
                    </button>
                    <h2>{process.displayName}</h2>
                  </div>
                  
                  <div className="process-actions">
                    {process.id === 'glow_01' && (
                      <div className="target-group-input">
                        <label htmlFor="targetGroupCount">{processConfig.uiStrings.targetGroupsLabel}</label>
                        <input
                          id="targetGroupCount"
                          type="number"
                          min="2"
                          max="20"
                          defaultValue="2"
                          className="target-group-count-input"
                        />
                      </div>
                    )}
                    <button
                      className={`process-button 
                        ${loadingButtons[process.id] ? 'loading' : ''} 
                        ${finishedProcesses[process.id] ? 'finished' : ''}
                        ${isAnyProcessRunning && !loadingButtons[process.id] && !finishedProcesses[process.id] ? 'waiting' : ''}
                      `}
                      onClick={() => processFunction(process.id)}
                      disabled={!canRunProcess(process.id, sessionDetails) || isSessionProcessRunning(sessionId) || loadingButtons[process.id]}
                    >
                      {loadingButtons[process.id] ? (
                        <>
                          <span className="loading-spinner" />
                          Running {process.displayName}...
                        </>
                      ) : finishedProcesses[process.id] ? (
                        <>
                          <FaCheck />
                          <span>{process.completedText}</span>
                          <span 
                            className="rerun-icon"
                            onClick={(e) => handleRerun(process.id, e)}
                            title="Run Again"
                          >
                            <FaRedo />
                          </span>
                        </>
                      ) : (
                        <>Run {process.displayName}</>
                      )}
                    </button>
                    <button
                      className="view-button"
                      onClick={() => viewResponse(process.id)}
                      disabled={!getProcessStatus(process.id, sessionDetails)}
                    >
                      {processConfig.uiStrings.viewJsonButton}
                    </button>
                  </div>

                  {!isProcessViewCollapsed[process.id] && 
                   getProcessStatus(process.id, sessionDetails) && (
                    <div className="process-view-container">
                      {process.id === 'glow_01' ? (
                        <Glow01ProcessorCustomer 
                          data={getProcessResponse(process.id, sessionDetails) || { target_groups: [] }} 
                        />
                      ) : process.id === 'glow_02' ? (
                        <Glow02ProcessorScene 
                          data={getProcessResponse(process.id, sessionDetails) || {}}
                          onUpdate={async (updatedData) => {
                            await updateSessionDetails(sessionId, updatedData);
                            await getSessionDetails(sessionId);
                          }}
                        />
                      ) : (
                        <Glow03ProcessorScript 
                          data={getProcessResponse(process.id, sessionDetails) || {}} 
                        />
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Initialize collapse state for all processes in useEffect
  useEffect(() => {
    const processes = getAllProcesses();
    const initialCollapseState = processes.reduce((acc, process) => {
      acc[process.id] = false;
      return acc;
    }, {});
    setIsProcessViewCollapsed(initialCollapseState);
  }, []);

  // Update the renderProcessButtons section in your main render area
  const renderProcessButtons = () => {
    if (!sessionId || !sessionDetails) return null;

    return (
      <div className="process-buttons">
        {getAllProcesses().map(process => (
          <div key={process.id} className="process-button-container">
            {renderProcessButton(process.id)}
          </div>
        ))}
      </div>
    );
  };

  // Update the process button rendering
  const renderProcessButton = (processId) => {
    const isLoading = loadingButtons[processId];
    const isOtherProcessRunning = isAnyProcessRunning && !isLoading;

    return (
      <button
        onClick={() => processFunction(processId)}
        disabled={isLoading || isOtherProcessRunning}
        className={`process-button ${isLoading ? 'loading' : ''} ${isOtherProcessRunning ? 'disabled' : ''}`}
      >
        {isLoading ? (
          <>
            <Lottie 
              animationData={loadingAnimation}
              loop={true}
              style={{ width: 20, height: 20, display: 'inline-block', marginRight: 5 }}
            />
            Running {getDisplayName(processId)}...
          </>
        ) : (
          getDisplayName(processId)
        )}
      </button>
    );
  };

  // Add this new function to handle the chain execution
  const runAllTextProcesses = async () => {
    try {
      // 1. Reset all states
      setFinishedProcesses({});
      setLoadingButtons(prev => ({ 
        ...prev, 
        'prepare-all': true  // Main button loading state
      }));
      setIsAnyProcessRunning(true);

      // 2. Get the text chain from config
      const textChain = processConfig.processChains.textChain;

      // 3. Run processes sequentially
      for (const processId of textChain) {
        // Set current process to loading
        setLoadingButtons(prev => ({
          ...prev,
          [processId]: true
        }));

        // Run the process
        await runProcess(processId);

        // Mark process as finished
        setFinishedProcesses(prev => ({
          ...prev,
          [processId]: true
        }));

        // Clear loading state for this process
        setLoadingButtons(prev => ({
          ...prev,
          [processId]: false
        }));

        // Small delay between processes
        await new Promise(resolve => setTimeout(resolve, 1000));
      }

      // 4. Chain completed successfully
      showToast(processConfig.uiStrings.toasts.processesCompleted, 'success');

    } catch (error) {
      console.error('Error in text chain:', error);
      showToast(processConfig.uiStrings.toasts.processesFailed, 'error');
    } finally {
      // 5. Reset states
      setLoadingButtons(prev => ({ 
        ...prev, 
        'prepare-all': false 
      }));
      setIsAnyProcessRunning(false);
    }
  };

  const handleRerun = (processId, e) => {
    e.stopPropagation(); // Prevent button click
    setFinishedProcesses(prev => ({
      ...prev,
      [processId]: false
    }));
    runProcess(processId);
  };

  // Add this function to handle rerun of all processes
  const handleRerunAll = (e) => {
    e.stopPropagation();
    setFinishedProcesses({});
    runAllTextProcesses();
  };

  // Add this function to handle feature requests
  const handleFeatureRequest = (featureId) => {
    showToast(`Feature "${featureId}" has been requested`, 'success');
    // You could also send this to your backend
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth * 0.8);
      setHeight(window.innerHeight * 0.6);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    console.log('Icicle Structure:', icicle_structure);
    console.log('Icicle Data:', icicleData);
  }, [icicleData]);

  useEffect(() => {
    setIcicleData({
      config: icicle_structure.config,
      children: icicle_structure.children
    });
  }, [icicle_structure]);

  const handleIcicleNodeClick = (nodeData) => {
    console.log('Clicked node:', nodeData);
    // Add your click handling logic here
    // For example, you could open a modal or navigate to a different view
  };

  const handleIcicleViewClick = (nodeData) => {
    const processId = ICICLE_TO_PROCESS_MAP[nodeData.id];
    if (!processId) {
      console.log('No process mapping found for:', nodeData.id);
      return;
    }

    // Find the process section and scroll to it
    const element = document.getElementById(`process-${processId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      element.classList.add('highlight');
      setTimeout(() => element.classList.remove('highlight'), 2000);
    }
  };

  const handleIciclePlayClick = async (nodeData) => {
    const processId = ICICLE_TO_PROCESS_MAP[nodeData.id];
    if (!processId) {
      console.log('No process mapping found for:', nodeData.id);
      return;
    }

    // Find the process in processConfig
    const process = processConfig.processes.find(p => p.id === processId);
    if (!process) {
      console.log('Process not found:', processId);
      return;
    }

    try {
      // Check if process can be run
      if (!sessionId) {
        showToast('Please select a session first');
        return;
      }

      if (isAnyProcessRunning) {
        showToast('Another process is currently running');
        return;
      }

      // Use existing runProcess function
      await runProcess(process.id);
    } catch (error) {
      console.error('Error running process:', error);
      showToast('Failed to run process');
    }
  };

  return (
    <div className={`App ${isDarkTheme ? 'theme-dark' : 'theme-light'}`}>
      <header className="App-header">
        <div className="header-left">
          <button className="icon-button" onClick={openSessionList}>
            <FaList /> {processConfig.uiStrings.sessionListButton}
          </button>
          <button className="icon-button" onClick={() => setDevToolOpen(!devToolOpen)}>
            <FaTools /> Dev Tool
          </button>
          <ThemeToggle isDark={isDarkTheme} onToggle={toggleTheme} />
        </div>
        
        {/* Add this logo container */}
        <div className="logo-container">
          <img 
            src={isDarkTheme ? '/images/logo-dark-desktop.png' : '/images/logo-light-desktop.png'} 
            alt="Logo" 
            className="logo"
          />
        </div>
      </header>
      
      <main className="main-content">
        <h1 className="main-title">
          {processConfig.uiStrings.mainTitle}
        </h1>

        <div className="input-container">
          <input
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder={processConfig.uiStrings.inputPlaceholder}
          />
          <button
            className={`process-button prepare-all 
              ${loadingButtons['prepare-all'] ? 'loading' : ''} 
              ${Object.keys(finishedProcesses).length === processConfig.processChains.textChain.length ? 'finished' : ''}`}
            onClick={runAllTextProcesses}
            disabled={!inputText.trim() || isAnyProcessRunning}
          >
            {(() => {
              // Using IIFE for complex conditional rendering
              if (loadingButtons['prepare-all']) {
                const runningProcess = Object.entries(loadingButtons)
                  .find(([id, isLoading]) => id !== 'prepare-all' && isLoading);
                
                return (
                  <>
                    <span className="loading-spinner" />
                    {runningProcess ? 
                      `Running ${getDisplayName(runningProcess[0])}...` : 
                      processConfig.uiStrings.preparingText}
                  </>
                );
              } else if (Object.keys(finishedProcesses).length === processConfig.processChains.textChain.length) {
                return (
                  <>
                    <FaCheck />
                    <span>Text Chain Complete</span>
                    <span 
                      className="rerun-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        setFinishedProcesses({});
                        runAllTextProcesses();
                      }}
                      title="Run Again"
                    >
                      <FaRedo />
                    </span>
                  </>
                );
              } else {
                return processConfig.uiStrings.prepareButton;
              }
            })()}
          </button>
        </div>

        <section className="icicle-section">
          {icicleData ? (
            <Icicle 
              data={icicleData} 
              onNodeClick={handleIcicleNodeClick}
              onViewClick={handleIcicleViewClick}
              onPlayClick={handleIciclePlayClick}
            />
          ) : (
            <div>Loading icicle data...</div>
          )}
        </section>
        
        {sessionDetails && (
          <div className="dashboard-content">
            <div className="process-controls">
              {renderProcessColumns()}
            </div>
          </div>
        )}
      </main>
      {devToolOpen && (
        <div className="dev-tool">
          <div className="dev-tool-header">
            <h2>Process Monitor</h2>
            <button className="close-button" onClick={() => setDevToolOpen(false)}>×</button>
          </div>
          {apiResponses.map((response, index) => (
            <div
              key={index}
              className={`api-response ${response.status ? 'success' : 'error'}`}
            >
              <div className="response-header">
                <button onClick={() => toggleResponseDetails(index)}>{response.message}</button>
                <span className="timestamp">{response.timestamp}</span>
              </div>
              {response.showDetails && (
                <pre>{formatJSON(JSON.stringify(response))}</pre>
              )}
            </div>
          ))}
        </div>
      )}
      {modalOpen && (
        <div className="modal editor-modal" ref={modalRef}>
          <div className="modal-content">
            <div className="modal-header">
              <span className="modal-title">{getProcessDisplayName(currentProcess)}</span>
              <div className="modal-actions">
                <button 
                  className="icon-action"
                  onClick={() => setIsJsonView(!isJsonView)}
                  title={isJsonView ? "Structured View" : "JSON View"}
                >
                  {isJsonView ? "👁️" : "{ }"}
                </button>
                <button 
                  className="icon-action"
                  onClick={() => copyToClipboard(modalContent)}
                  title="Copy to Clipboard"
                >
                  <FaCopy />
                </button>
                <button 
                  className="icon-action"
                  onClick={() => downloadContent(modalContent, getProcessDisplayName(currentProcess))}
                  title="Download"
                >
                  <FaDownload />
                </button>
                <button 
                  className="icon-action"
                  onClick={() => setShowSaveConfirmation(true)}
                  title="Save Changes"
                  disabled={!hasUnsavedChanges}
                >
                  <FaSave />
                </button>
                <button 
                  className="icon-action close-button"
                  onClick={handleModalClose}
                  title="Close"
                >
                  ×
                </button>
              </div>
            </div>
            <div className="editor-container">
              {isJsonView ? (
                <textarea
                  value={modalContent}
                  onChange={handleModalContentChange}
                  spellCheck="false"
                />
              ) : (
                isValidJson(modalContent) && (
                  <Glow01ProcessorCustomer data={JSON.parse(modalContent)} />
                )
              )}
            </div>
          </div>

          {/* Save Confirmation Modal */}
          {showSaveConfirmation && (
            <div className="confirmation-modal">
              <div className="confirmation-content">
                <h3>Save Changes</h3>
                <p>Do you want to save the changes?</p>
                <div className="confirmation-actions">
                  <button 
                    onClick={handleSaveConfirm}
                    className="confirm-button"
                    disabled={isSaving}
                  >
                    {isSaving ? (
                      <>
                        <span className="loading-spinner" />
                        Saving...
                      </>
                    ) : 'Save'}
                  </button>
                  <button 
                    onClick={() => setShowSaveConfirmation(false)}
                    className="cancel-button"
                    disabled={isSaving}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Discard Changes Confirmation Modal */}
          {showDiscardConfirmation && (
            <div className="confirmation-modal">
              <div className="confirmation-content">
                <h3>Unsaved Changes</h3>
                <p>You have unsaved changes. What would you like to do?</p>
                <div className="confirmation-actions">
                  <button 
                    onClick={() => {
                      setShowDiscardConfirmation(false);
                      setShowSaveConfirmation(true);
                    }}
                    className="save-button"
                  >
                    Save
                  </button>
                  <button 
                    onClick={handleDiscardConfirm}
                    className="discard-button"
                  >
                    Discard
                  </button>
                  <button 
                    onClick={() => setShowDiscardConfirmation(false)}
                    className="cancel-button"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {toast.show && (
        <div className={`toast ${toast.type}`}>
          {toast.message}
        </div>
      )}
      {deleteConfirmation && (
        <div className="delete-confirmation-modal">
          <div className="delete-confirmation-content">
            <h3>Confirm Deletion</h3>
            <p>Are you sure you want to delete the session "{deleteConfirmation.name}"?</p>
            <div className="delete-confirmation-actions">
              <button 
                onClick={confirmDeleteSession} 
                className="delete-confirm"
                disabled={isDeleting}
              >
                Delete
              </button>
              <button 
                onClick={cancelDeleteSession} 
                className="delete-cancel"
                disabled={isDeleting}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {isDeleting && (
        <div className="loading-overlay">
          <div className="loading-content">
            <Lottie 
              animationData={loadingAnimation}
              loop={true}
              style={{ width: 100, height: 100 }}
            />
            <p>Deleting session...</p>
          </div>
        </div>
      )}
      {showSessionList && (
        <div className="session-list-modal">
          <div className="session-list-content">
            <div className="session-list-header">
              <h3>Session List</h3>
              <button className="close-button" onClick={() => setShowSessionList(false)}>×</button>
            </div>
            <button 
              className="create-new-session" 
              onClick={createSession}
              disabled={isCreating}
            >
              {isCreating ? (
                <>
                  <span className="loading-spinner" />
                  Creating...
                </>
              ) : (
                <>
                  <FaPlusSquare /> {processConfig.uiStrings.createNewVideo}
                </>
              )}
            </button>
              {sessionList.map((session) => (
                <div key={session.session_id} className={`session-item ${isDeleting ? 'deleting' : ''}`}>
                  <div 
                    className="session-select-indicator"
                    onClick={() => selectSession(session.session_id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {session.session_id === sessionId ? (
                      <FaCheckCircle color="#4CAF50" />
                    ) : (
                      <FaCircle color="#ccc" />
                    )}
                  </div>
                  {editingSessionId === session.session_id ? (
                    <>
                      <input
                        type="text"
                        value={newSessionName}
                        onChange={(e) => setNewSessionName(e.target.value)}
                        placeholder={session.session_name}
                        disabled={isRenaming || isSessionProcessRunning(session.session_id)}
                      />
                      <button 
                        onClick={() => confirmRenameSession(session.session_id)} 
                        disabled={!newSessionName || !newSessionName.trim() || isRenaming || isSessionProcessRunning(session.session_id)}
                      >
                        {isRenaming ? (
                          <span className="loading-spinner" />
                        ) : '✓'}
                      </button>
                      <button 
                        onClick={cancelRenameSession}
                        disabled={isRenaming || isSessionProcessRunning(session.session_id)}
                      >
                        ✗
                      </button>
                    </>
                  ) : (
                    <>
                      <span className="session-name">{session.session_name}</span>
                      <button 
                        onClick={() => selectSession(session.session_id)} 
                        disabled={session.session_id === sessionId || isSessionProcessRunning(session.session_id)}
                        className={isSessionProcessRunning(session.session_id) ? 'disabled' : ''}
                      >
                        {session.session_id === sessionId ? processConfig.uiStrings.selectedButton : processConfig.uiStrings.selectButton}
                      </button>
                      <button 
                        onClick={() => startRenameSession(session.session_id, session.session_name)}
                        disabled={isSessionProcessRunning(session.session_id)}
                        className={isSessionProcessRunning(session.session_id) ? 'disabled' : ''}
                      >
                        {processConfig.uiStrings.renameButton}
                      </button>
                      <button 
                        onClick={() => initiateDeleteSession(session.session_id, session.session_name)}
                        disabled={isSessionProcessRunning(session.session_id)}
                        className={isSessionProcessRunning(session.session_id) ? 'disabled' : ''}
                      >
                        {processConfig.uiStrings.deleteButton}
                      </button>
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
      {/* Loading Overlay */}
      {isSaving && (
        <div className="loading-overlay">
          <div className="loading-content">
            <Lottie 
              animationData={loadingAnimation}
              loop={true}
              style={{ width: 100, height: 100 }}
            />
            <p>Saving changes...</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
