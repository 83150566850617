import React from 'react';
import { FaSun, FaMoon } from 'react-icons/fa';

const ThemeToggle = ({ isDark, onToggle }) => {
  return (
    <div className="theme-switch">
      <FaSun color={isDark ? "#717585" : "#FD9800"} />
      <label className="switch">
        <input
          type="checkbox"
          checked={isDark}
          onChange={onToggle}
        />
        <span className="slider"></span>
      </label>
      <FaMoon color={isDark ? "#FD9800" : "#717585"} />
    </div>
  );
};

export default ThemeToggle;
