import processIndex from '../config/processIndex.json';
import axios from 'axios';
import Cookies from 'js-cookie';

export const getProcessById = (id) => {
  return processIndex.processes.find(process => process.id === id);
};

export const getProcessByOrder = (order) => {
  return processIndex.processes.find(process => process.displayOrder === order);
};

export const getDisplayName = (id) => {
  const process = getProcessById(id);
  return process ? process.displayName : id;
};

export const canRunProcess = (id, sessionDetails) => {
  const process = getProcessById(id);
  if (!process) return false;
  
  // If there are no dependencies, the process can run
  if (!process.dependsOn || process.dependsOn.length === 0) {
    return true;
  }

  // Check if sessionDetails exists
  if (!sessionDetails) {
    return false;
  }

  // Check dependencies
  return process.dependsOn.every(dependencyId => {
    const dependency = getProcessById(dependencyId);
    // Add null check for dependency
    if (!dependency) {
      console.warn(`Dependency ${dependencyId} not found for process ${id}`);
      return false;
    }
    return sessionDetails[dependency.statusKey];
  });
};

export const getProcessResponse = (id, sessionDetails) => {
  const process = getProcessById(id);
  if (!process || !sessionDetails) return null;

  const responseData = sessionDetails[process.responseKey];
  return responseData ? responseData[`${process.id}_response`] : null;
};

export const getProcessStatus = (id, sessionDetails) => {
  const process = getProcessById(id);
  return process ? sessionDetails[process.statusKey] : false;
};

export const getEndpoint = (id, type) => {
  const process = getProcessById(id);
  return process ? `${processIndex.apiBaseUrl}/${process.endpoints[type]}` : null;
};

export const getAllProcesses = () => {
  return [...processIndex.processes].sort((a, b) => a.displayOrder - b.displayOrder);
};

export const updateSessionDetails = async (sessionId, updatedData) => {
  try {
    const response = await axios.post(
      `${processIndex.apiBaseUrl}/session/update_session_details`,
      {
        session_id: sessionId,
        session_details: updatedData
      },
      { 
        headers: { 
          Authorization: `Bearer ${Cookies.get('token')}` 
        } 
      }
    );

    if (response.data.status_code === 200) {
      return response.data;
    } else {
      throw new Error(response.data.message || 'Failed to update session details');
    }
  } catch (error) {
    console.error('Error updating session details:', error);
    throw error;
  }
};

export const getUpdateEndpoint = (processId) => {
  const process = getProcessById(processId);
  return process ? `${processIndex.apiBaseUrl}/${process.endpoints.update}` : null;
};

export const updateProcessData = async (processId, sessionId, updatedData, type = 'full') => {
  try {
    const endpoint = getUpdateEndpoint(processId);
    if (!endpoint) {
      throw new Error(`No update endpoint found for process ${processId}`);
    }

    const process = getProcessById(processId);
    const payload = {
      session_id: sessionId,
      update_type: type,
      [process.responseKey]: {
        [`${process.id}_response`]: updatedData
      }
    };

    const response = await axios.post(
      endpoint,
      payload,
      { 
        headers: { 
          Authorization: `Bearer ${Cookies.get('token')}` 
        } 
      }
    );

    if (response.data.status_code === 200) {
      return response.data;
    } else {
      throw new Error(response.data.message || `Failed to update ${process.displayName}`);
    }
  } catch (error) {
    console.error(`Error updating ${processId}:`, error);
    throw error;
  }
};

export const validateProcessUpdate = (processId, data) => {
  const process = getProcessById(processId);
  if (!process) return false;

  // Get validation rules for this process
  const { validations } = process;
  if (!validations) return true;

  try {
    // Check required fields
    if (validations.required) {
      for (const field of validations.required) {
        if (!data[field]) {
          throw new Error(`Missing required field: ${field}`);
        }
      }
    }

    // Check max lengths
    if (validations.maxLength) {
      for (const [field, maxLength] of Object.entries(validations.maxLength)) {
        if (data[field] && data[field].length > maxLength) {
          throw new Error(`${field} exceeds maximum length of ${maxLength}`);
        }
      }
    }

    return true;
  } catch (error) {
    console.error('Validation error:', error);
    return false;
  }
};

export const updateProcessField = async (processId, sessionId, fieldPath, value) => {
  try {
    const currentData = await getProcessResponse(processId, await getSessionDetails(sessionId));
    if (!currentData) {
      throw new Error('No existing data found to update');
    }

    // Create updated data by modifying only the specified field
    const updatedData = {...currentData};
    const pathArray = fieldPath.split('.');
    let target = updatedData;
    
    // Navigate to the nested property
    for (let i = 0; i < pathArray.length - 1; i++) {
      target = target[pathArray[i]];
    }
    
    // Update the value
    target[pathArray[pathArray.length - 1]] = value;

    // Validate the update
    if (!validateProcessUpdate(processId, updatedData)) {
      throw new Error('Invalid update data');
    }

    // Send the update
    return await updateProcessData(processId, sessionId, updatedData, 'partial');
  } catch (error) {
    console.error('Error updating field:', error);
    throw error;
  }
};

export const batchUpdateProcess = async (processId, sessionId, updates) => {
  try {
    const currentData = await getProcessResponse(processId, await getSessionDetails(sessionId));
    if (!currentData) {
      throw new Error('No existing data found to update');
    }

    const updatedData = {...currentData};
    
    // Apply all updates
    for (const [fieldPath, value] of Object.entries(updates)) {
      const pathArray = fieldPath.split('.');
      let target = updatedData;
      
      for (let i = 0; i < pathArray.length - 1; i++) {
        target = target[pathArray[i]];
      }
      
      target[pathArray[pathArray.length - 1]] = value;
    }

    // Validate the complete update
    if (!validateProcessUpdate(processId, updatedData)) {
      throw new Error('Invalid update data');
    }

    // Send the update
    return await updateProcessData(processId, sessionId, updatedData, 'full');
  } catch (error) {
    console.error('Error batch updating process:', error);
    throw error;
  }
};

export const getSessionDetails = async (sessionId) => {
  try {
    const response = await axios.get(
      `${processIndex.apiBaseUrl}/session/get_session_details`,
      {
        params: { session_id: sessionId },
        headers: { Authorization: `Bearer ${Cookies.get('token')}` }
      }
    );

    if (response.data.status_code === 200) {
      return response.data.session_details;
    } else {
      throw new Error(response.data.message || 'Failed to get session details');
    }
  } catch (error) {
    console.error('Error getting session details:', error);
    throw error;
  }
};

