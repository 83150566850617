import React, { useState } from 'react';
import './Viewers.css';

const Glow02ProcessorScene = ({ data }) => {
  const [expandedScenes, setExpandedScenes] = useState({});

  const toggleScene = (sceneId) => {
    setExpandedScenes(prev => ({
      ...prev,
      [sceneId]: !prev[sceneId]
    }));
  };

  const renderScene = (scene) => {
    const isExpanded = expandedScenes[scene.scene_number];

    return (
      <div key={scene.scene_number} className="target-group">
        <h3 onClick={() => toggleScene(scene.scene_number)}>
          <span className={`collapse-indicator ${isExpanded ? 'expanded' : ''}`}>▶</span>
          Scene {scene.scene_number}: {scene.scene_title}
        </h3>
        
        {isExpanded && (
          <div className="group-content">
            <div className="problem-solution">
              <div className="section">
                <h4>Purpose & Emotion:</h4>
                <p><strong>Purpose:</strong> {scene.purpose}</p>
                <p><strong>Emotion:</strong> {scene.emotion}</p>
              </div>
              
              <div className="section">
                <h4>Visual & Audio:</h4>
                <p><strong>Visuals Basic:</strong> {scene.scene_visuals_basics}</p>
                <p><strong>Visuals Extensive:</strong> {scene.scene_visuals_extensive}</p>
                <p><strong>Background Audio:</strong> {scene.scene_audio_background}</p>
                <p><strong>Narration:</strong> {scene.audio_narration}</p>
              </div>
            </div>
            
            <div className="section">
              <h4>Success Metric:</h4>
              <p>{scene.success_metric}</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderTargetGroup = (groupData, groupId) => {
    const isExpanded = expandedScenes[`group_${groupId}`];
    
    return (
      <div key={groupId} className="target-group">
        <h3 
          onClick={() => toggleScene(`group_${groupId}`)}
        >
          <span className={`collapse-indicator ${isExpanded ? 'expanded' : ''}`}>▶</span>
          {groupData.target_group}
        </h3>
        
        {isExpanded && (
          <div className="group-content">
            {/* Video Base Settings */}
            <div className="section">
              <h4>Video Base Settings:</h4>
              <p><strong>Style:</strong> {groupData.video_base.style}</p>
              <p><strong>Color Scheme:</strong> {groupData.video_base.color_scheme}</p>
              <p><strong>Duration:</strong> {groupData.video_base.target_duration_in_sec} seconds</p>
              
              <div className="audio-base">
                <h4>Audio Base:</h4>
                <p><strong>Timbre:</strong> {groupData.video_base.audio_base.timbre}</p>
                <p><strong>Tempo:</strong> {groupData.video_base.audio_base.tempo}</p>
                <p><strong>Core Rhythm:</strong> {groupData.video_base.audio_base.core_rhythm}</p>
                <p><strong>Harmonic Base:</strong> {groupData.video_base.audio_base.harmonic_base}</p>
              </div>
            </div>

            {/* Scenes */}
            <div className="scenes-container">
              <h4>Scenes:</h4>
              {groupData.scenes.map(scene => renderScene(scene))}
            </div>
          </div>
        )}
      </div>
    );
  };

  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <div className="process-viewer">
      <div className="target-groups-container">
        {Object.entries(data).map(([groupId, group]) => 
          renderTargetGroup(group, groupId)
        )}
      </div>
    </div>
  );
};

export default Glow02ProcessorScene;
